import React from "react"
import "../components/layout.scss"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Container, Row, Col, Navbar, ListGroup } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import SimpleContactForm from "../components/simpleContactForm"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCalendarAlt,
  faMoneyBillWave,
  faBalanceScaleLeft,
  faCheckCircle,
  faCheck,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons"
import RequestCaseEvaluationCTA from "../components/requestCaseEvaluationCTA"
import { dom, config } from "@fortawesome/fontawesome-svg-core"
import videoPoster from "../images/cover4.jpg"

// Disable the automatic insertion
// of CSS into the head of the document.
config.autoAddCss = false

export default ({ data, location }) => (
  <section>
    {/* {JSON.stringify(location)} */}
    <Helmet
      title="Debt Relief Attorney - Russo, White &amp; Keller, P.C."
      defer={false}
    >
      <style type="text/css">{dom.css()}</style>
    </Helmet>
    {/* <video
      id="videoBG"
      poster={videoPoster}
      autoPlay
      muted
      loop
      style={{
        position: "absolute",
        top: 0,
        zIndex: -1,
        width: "100%",
        height: "calc(100vh - 80px)",
        minHeight: "600px",
        objectFit: "cover",
      }}
    >
      <source
        src="https://cdn.rwkattorneys.com/bankruptcy-cover.mp4"
        type="video/mp4"
      />
    </video> */}

    <BackgroundImage
      fluid={[
        `linear-gradient(0deg, rgba(255,255,255,.85) 0%, rgba(255,255,255,.85) 100%)`,
        data.cover.childImageSharp.fluid,
      ]}
      backgroundColor={`#040e18`}
      aria-label="gbitest"
    >
      <Navbar
        bg="light"
        expand="lg"
        className="shadow-sm text-dark"
        style={{ minHeight: "10vh" }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/" className="mx-auto mx-sm-0">
            Russo, White &amp; Keller
            <span className="d-none d-sm-inline-block">, P.C.</span>
          </Navbar.Brand>

          <section className="mx-auto mr-xl-0 ml-xl-auto text-center pt-2 position-relative">
            <h3 className="mb-0">
              <a href="tel://+12058332589" className="stretched-link">
                (205) 833-2589
              </a>
            </h3>
            <small
              className="text-uppercase mb-0 font-weight-bold"
              style={{ letterSpacing: "1px" }}
            >
              Free Case Evaluation
            </small>
          </section>
        </Container>
      </Navbar>
      <Container fluid>
        <Row className="text-center text-uppercase font-weight-bolder">
          <Col
            className="px-0 px-3 py-2 bg-secondary text-light"
            style={{ letterSpacing: "4px" }}
          >
            Relief is just a click away. Eliminate most of your debts.
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        // style={{
        //   background:
        //     "linear-gradient(0deg, rgba(255,255,255,.85) 0%, rgba(255,255,255,.85) 100%)",
        // }}
      >
        <Container>
          <Row className="text-dark align-items-center align-items-md-start justify-content-center">
            <Col xs="12">
              <h1 className="text-dark text-center pt-4 pb-2 pb-lg-4">
                Birmingham
                <br />
                Bankruptcy Attorneys
              </h1>
            </Col>

            <Col
              className="h-100"
              sm={{ span: 10, offset: 1 }}
              md={{ span: 6, offset: 0 }}
              xl="6"
            >
              <p className="text-secondary text-uppercase font-weight-bold mb-1">
                Take the guesswork out of your finances
              </p>
              <p>
                We will evaluate your situation, lower your debts and create a
                repayment plan customized to your needs. We will structure your
                payments so you can pay for necessities like food, clothing,
                transportation, medical expenses, and utilities while we get
                your finances back on track.
                {/*  */}
              </p>
              <p>
                Start with a free evaluation, you have nothing to lose and
                everything to gain.
              </p>

              <ListGroup variant="flush" className="pb-3">
                <ListGroup.Item className="bg-transparent d-flex align-content-between align-items-center">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-secondary fa-lg"
                  />{" "}
                  <span className="ml-4">Evaluations are completely free.</span>
                </ListGroup.Item>
                <ListGroup.Item className="bg-transparent d-flex align-content-between align-items-center">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-secondary fa-lg"
                  />{" "}
                  <span className="ml-4">
                    You are under no obligation to hire our firm.
                  </span>
                </ListGroup.Item>
                <ListGroup.Item className="bg-transparent d-flex align-content-between align-items-center">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-secondary fa-lg"
                  />{" "}
                  <span className="ml-4">Get your financial freedom back.</span>
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col
              xs="12"
              md="6"
              xl="4"
              className="text-light px-5 py-5"
              style={{
                background:
                  "linear-gradient(0deg, rgba(1,110,153,1) 80%, rgba(1,110,153,.5) 100%)",
              }}
            >
              <h4 className="text-shadow-light text-uppercase">
                Request Evaluation
              </h4>
              <p>Get your case reviewed by an experienced attorney.</p>
              <SimpleContactForm type="bankruptcy" />
            </Col>
          </Row>
        </Container>
      </Container>
    </BackgroundImage>
    <Container fluid className="text-center bg-secondary text-light">
      <Container>
        <Row>
          <Col xs="12" sm="4" xl="4" className="py-3">
            <section className="bg-light text-dark shadow d-flex align-items-center justify-content-center p-2 p-sm-3">
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-2x" />
              <div className="ml-2">Flexible Appointments</div>
            </section>
          </Col>
          <Col xs="12" sm="4" xl="4" className="py-0 py-sm-3">
            <section className="bg-light text-dark shadow d-flex align-items-center justify-content-center p-2 p-sm-3">
              <FontAwesomeIcon icon={faMoneyBillWave} className="fa-2x" />
              <div className="ml-2">Affordable Services</div>
            </section>
          </Col>
          <Col xs="12" sm="4" xl="4" className="py-3">
            <section className="bg-light text-dark shadow d-flex align-items-center justify-content-center p-2 p-sm-3">
              <FontAwesomeIcon icon={faBalanceScaleLeft} className="fa-2x" />
              <div className="ml-2">Experienced Legal Team</div>
            </section>
          </Col>
        </Row>
      </Container>
    </Container>

    <BackgroundImage
      fluid={[
        `linear-gradient(0deg, rgba(255,255,255,.9) 0%, rgba(255,255,255,.9) 100%)`,
        data.cover2.childImageSharp.fluid,
      ]}
      backgroundColor={`#040e18`}
      aria-label="gbitest"
    >
      <Container className="text-center text-dark py-xl-5">
        <Row
          className="align-items-center justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <Col xl="8" className="text-center mb-5">
            <h2 className="mt-5 mb-3 mt-md-3 text-primary">
              Experienced Representation
            </h2>
            <p>
              At Russo, White &amp; Keller, P.C., we help our clients address
              their financial problems while considering the other factors
              important to them - keeping your assets and putting a stop to
              creditor harassment.
            </p>
            <p>
              Bankruptcy is never an easy decision but sometimes it is the right
              decision. We help individuals and businesses evaluate their
              situation and options. We will fight to protect your rights under
              bankruptcy and consumer laws.
            </p>
            <p>
              If you are struggling with one or more of the following, we can
              help:
            </p>

            <Row className="my-4">
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Creditor Harassment</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Rent Payments</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Vehicle Payments</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Mortgage Payments</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Automatic Stay Violations</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Discharge Violations</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Lost Job</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Lost Wages</div>
              </Col>
              <Col xs="4" className="p-3">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary fa-lg"
                />
                <div>Child Support</div>
              </Col>
            </Row>

            <RequestCaseEvaluationCTA type="bankruptcy" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  </section>
)
export const query = graphql`
  query {
    cover: file(relativePath: { eq: "cover4.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cover2: file(relativePath: { eq: "cover5.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
